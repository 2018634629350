import { TextContent } from "@cloudscape-design/components";

export function Tools() {
    // intro about ChatSuSu
    return (
        <div id='tools' style={{ alignContent: 'center', width: '80%', height: '100%', margin: 'auto', paddingTop: '10%' }}>
            <div id='app-logo' style={{ textAlign: 'center' }}>
                <img src={require('./logo192.png')} width={80} />
                <h4>ChatSuSu</h4>
                <p>
                    ChatSuSu chỉ dành cho người dùng từ 17 tuổi trở lên ở lãnh thổ Việt Nam.
                </p> 
            </div>
            
            {/* divider */}
            <hr></hr>
            
            {/* Press Release section */}
            <div id='press-release' style={{ textAlign: 'center' }}>
                <h2>Giới Thiệu</h2>
                <TextContent>
                    <p>
                    ChatSuSu, một chatbot AI thân thiện và đáng tin cậy của bạn.
                    </p>
                    <p>
                    ChatSuSu được thiết kế để cung cấp hỗ trợ đặc biệt thông qua các thuật toán thông minh cho phép ChatSuSu hiểu và trả lời các câu hỏi của người dùng.
                    </p>
                    <p>
                    Với ChatSuSu, bạn có phản hồi nhanh chóng và hiệu quả, 24/7 và trải nghiệm cá nhân phù hợp với nhu cầu của bạn.
                    </p>
                </TextContent>
            </div>

            {/* divider */}
            <hr></hr>

            {/* Disclaimer section */}
            <div id='disclaimer' style={{ textAlign: 'center' }}>
                <h2>Disclaimer - Miễn Trừ Trách Nhiệm</h2>
                <TextContent>
                    <p>
                        ChatSuSu là một chatbot AI hữu ích, luôn sẵn sàng hỗ trợ bạn với bất kỳ câu hỏi hay mối quan tâm nào. 
                        Tuy nhiên, điều quan trọng cần lưu ý là một AI, ChatSuSu không phải lúc nào cũng chính xác 100%. 
                        Phản hồi của ChatSuSu được tạo ra dựa trên các mô hình và dữ liệu huấn luyện. 
                        Vì vậy có thể có những trường hợp mà câu trả lời của ChatSuSu không phù hợp với thực tế hoặc tình hình hiện tại. 
                        Ngoài ra, một số thông tin ChatSuSu cung cấp có thể chưa được cập nhật hoặc chính xác. 
                        Vì vậy, trong khi ChatSuSu ở đây để giúp đỡ, hãy luôn kiểm tra lại bất kỳ thông tin nào ChatSuSu cung cấp với một nguồn đáng tin cậy trước khi đưa ra quyết định quan trọng.
                    </p>
                    <p>
                        ChatSuSu cũng không phải là một chuyên gia y tế, vì vậy ChatSuSu không thể cung cấp lời khuyên y tế. 
                        Nếu bạn có bất kỳ câu hỏi nào liên quan đến sức khỏe của bạn, hãy tham khảo ý kiến chuyên gia y tế của bạn.
                    </p>

                    <p>
                        Khi sử dụng ChatSuSu, bạn đồng ý với các điều khoản và điều kiện của chúng tôi.
                    </p>
                </TextContent>
            </div>

            {/* divider */}
            <hr></hr>

            {/* space fillter bottom */}
            <div style={{ height: '100px' }}></div>
            
        </div>
    );
}