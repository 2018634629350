/**
 * This class is used to measure the latency of an event.
 * @example
 * const latency = PerformanceLatency.start('search');
 * // do something
 * console.log(latency.getLatency()); // get the latency in ms from start up to this point
 */
export default class PerformanceLatency {
    private _name: string;
    private _start: number;

    constructor(_name: string) {
        this._start = new Date().getTime();
        this._name = _name;
    }

    /**
     * Restart the timer
     * @returns {void}
     * @memberof PerformanceLatency
     */
    restart(): void {
        this._start = new Date().getTime();
    }

    /**
     * Get the latency in ms, measure from when obj is created, or restarted till this func is called.
     * @returns {number} latency in ms
     * @memberof PerformanceLatency
     */
    getLatencyMs(): number {
        return new Date().getTime() - this._start;
    }

    static start(_name: string): PerformanceLatency {
        return new PerformanceLatency(_name);
    }
}
