import { Container, SideNavigation, TextContent } from "@cloudscape-design/components";
import { LocalAppConfig } from './local-app-config';
// export function ChatPanel(props: { prevTaskResps: TaskResp[], currentTaskResp: TaskResp | undefined, partialAnswer: string | undefined }) {
export function NavContents () {
    const l = LocalAppConfig;
    return (
        <SideNavigation 
            // header = {{
            //     text: `${l.appName}-${l.version}`,
            //     href: '/',
            // }}
            items = {
                [
                    {
                        type: 'link',
                        text: '',
                        href: '#',
                        info: <div id='app-logo' style={{textAlign: 'center'}}>
                            <img src={require('./logo192.png')} width={120} />
                            <br></br>
                            <h2>
                                {`${l.appName}`}
                            </h2>
                            <h4>
                                {`${l.platform} v${l.version}`}
                            </h4>
                        </div>
                    },
                    {
                        type: 'divider',
                    },
                    {
                        type: 'link',
                        text: '',
                        href: '#intro',
                        info: <div>
                            <p>ChatSuSu phiên bản thử nghiệm.</p>
                            <p>ChatSuSu hỗ trợ song ngữ Anh Việt.</p>
                            <p>ChatSuSu chỉ dành cho người dùng từ 17 tuổi trở lên ở lãnh thổ Việt Nam.</p>
                        </div>
                    },
                    {
                        type: 'divider',
                    },
                    {
                        type: 'link',
                        text: '',
                        href: '#copy-right',
                        info: <div>
                            <span>© 2023 ChatSuSu</span>
                        </div>
                    },
                ]
            }
        />
    )
}

{/* <div id='nav-panel-main' style={{backgroundColor: 'yellow', alignContent: 'center', width: '80%', height: '100%', margin: 'auto'}}>
    <Container>
        <div id='app-logo' style={{backgroundColor: 'purple', textAlign: 'center'}}>
            <img src={require('./logo192.png')} />
        </div>
    </Container>
</div> */}