import { API } from "aws-amplify";

/**
 * Data manager for all data needs.
 * 
 * React app will use this to get data from the server.
 * Data will be conviently cached here.
 * 
 */
class AllDataManager {
    private CACHE_EXPIRATION_TIME = 24 * 60 * 60 * 1000; // 24 hours
    private appConfig: AppConfig = {
        cachedTime: undefined,
        appName: "",
        appVersion: "",
        appIdentifier: "",
        appIsActive: false,
        appDescription: "",
        suggestedQuestions: []
    }; // init with default app config

    constructor() {}

    /**
     * Initialize the data manager with required remote data.
     * MUST Do it once, when the app is ready: Amplify library is ready, etc.
     */
    public async initialize() {
        // get the real app config here
        // console.debug('initialize AllDataManager');
        this.appConfig = await this.getAppConfig();
    }

    //
    //
    // =============== getSuggestedQuestion ===============
    //
    //

    /**
     * Logic: Returns a suggested question by reading the appConfig.suggestedQuestions.
     * Try to return a question that has not been asked before.
     * If all questions have been asked, return a random question.
     * 
     * @returns {string}} suggestedQuestion
     */
    public getSuggestedQuestion(): string {
        const suggestedQuestions = this.appConfig.suggestedQuestions;

        // handle suggestedQuestions is empty
        if (suggestedQuestions.length === 0) {
            return "";
        }

        const askedQuestions = this.getAskedQuestions();
        const unaskedQuestions = suggestedQuestions.filter((suggestedQuestion) => {
            return !askedQuestions.includes(suggestedQuestion.code);
        });

        // handling when all questions have been asked, return a random question
        // TODO find a better algorithm - this is not good enough
        if (unaskedQuestions.length === 0) {
            const randomIndex = Math.floor(Math.random() * suggestedQuestions.length);
            return suggestedQuestions[randomIndex].question;
        }

        // happy path, return a random question from the unaskedQuestions
        const randomIndex = Math.floor(Math.random() * unaskedQuestions.length);

        return unaskedQuestions[randomIndex].question;
    }

    /**
     * Store the asked question "code" in the local storage.
     * eg: ["EN2349A", "EN2349B"]
     * @returns {string[]} askedQuestions codes
     */
    private getAskedQuestions() : string[] {
        const askedQuestions = localStorage.getItem("asked_questions");
        if (askedQuestions) {
            return JSON.parse(askedQuestions);
        } else {
            return [];
        }
    }

    /**
     * Remember the asked question "code" in the local storage.
     * @param questionCode 
     */
    public rememberAskedSuggestedQuestion(question: string) {
        const suggestedQuestions = this.appConfig.suggestedQuestions;
        const questionCode = suggestedQuestions.find((suggestedQuestion) => {
            return suggestedQuestion.question === question;
        })?.code;
        
        if (questionCode === undefined) {
            console.debug('nothing to do, question is not in suggestedQuestions')
            return;
        }

        console.debug('rememberAskedSuggestedQuestion', questionCode);
        const askedQuestions = this.getAskedQuestions();
        askedQuestions.push(questionCode);
        localStorage.setItem("asked_questions", JSON.stringify(askedQuestions));
    }

    //
    //
    // =============== App Config ===============
    //
    //

    /**
     * Logic: Returns a cached app config if available, otherwise fetch from server.
     * If the cached app config is expired, fetch from server and cache it.
     * If nothing is available, return a default app config.
     * 
     * @returns {Promise<AppConfig>} app config
     */

    async getAppConfig(): Promise<AppConfig> {
        const cachedAppConfig = localStorage.getItem("app_config");

        // handle edge case early
        if (!cachedAppConfig || this.isAppConfigExpired(cachedAppConfig)) {
            let _appConfig = await this.fetchAppConfig();
            if (!_appConfig) {
                _appConfig = defaultAppConfig;
            }

            this.cacheAppConfig(_appConfig);
            return _appConfig;
        }

        // happy path
        return JSON.parse(cachedAppConfig);

    }

    /**
     * Check if the cached app config is expired.
     * 
     * @param {string} cachedAppConfig - The cached app config
     * @returns {boolean} true if expired, false otherwise
     */
    private isAppConfigExpired(cachedAppConfig: string): boolean {
        const parsedAppConfig = JSON.parse(cachedAppConfig);
        const cachedTime = parsedAppConfig.cachedTime;
        return Date.now() - cachedTime > this.CACHE_EXPIRATION_TIME;
    }

    /**
     * Fetch the app config from the server.
     * 
     * @returns {Promise<AppConfig>} app config
     */
    private async fetchAppConfig(): Promise<AppConfig> {
        try {
            let bodyPayload = {
                action: 'appConfig',
            };
            const appConfigRemote = await API.post('TamCamEyAiEndpoint', '/tamcam/actions', {
                body: bodyPayload
            });
            // console.debug('appConfigRemote', appConfigRemote);
    
            // handle errors
            if (appConfigRemote.error) {
                console.error('Error fetching app config', appConfigRemote.error);
                return defaultAppConfig;
            }
    
            // handle if app config is not expected interface, missing required appIdentifier attribute
            if (!appConfigRemote.appIdentifier) {
                console.error('Error fetching app config, missing appIdentifier', appConfigRemote);
                return defaultAppConfig;
            }
    
            return appConfigRemote;

        } catch (error) {
            console.error('Returning default app config because there was Error fetching app config', error);
            return defaultAppConfig;
        }
    }

    /**
     * Cache the app config in the local storage.
     * 
     * @param {AppConfig} appConfig - The app config to cache
     */
    private cacheAppConfig(appConfig: AppConfig): void {
        appConfig.cachedTime = Date.now();
        localStorage.setItem("app_config", JSON.stringify(appConfig));
    }
}

export default new AllDataManager();

// == Helper functions ==

interface AppConfig {
    cachedTime: number | undefined;
    appName: string;
    appVersion: string;
    appIdentifier: string;
    appIsActive: boolean;
    appDescription: string;
    suggestedQuestions: {
        code: string;
        question: string;
    }[];
}
const defaultAppConfig: AppConfig = {
    "appName": "ChatSuSu",
    "appVersion": "1.0.6",
    "appIdentifier": "com.chatsusu.web",
    "appIsActive": true,
    "appDescription": "ChatSuSu, một chatbot AI thân thiện và đáng tin cậy của bạn. ChatSuSu được thiết kế để cung cấp hỗ trợ đặc biệt thông qua các thuật toán thông minh cho phép ChatSuSu hiểu và trả lời các câu hỏi của người dùng. Với ChatSuSu, bạn có phản hồi nhanh chóng và hiệu quả, 24/7 và trải nghiệm cá nhân phù hợp với nhu cầu của bạn.",
    "suggestedQuestions": [
        {
            "code": "EN2349A",
            "question": "As a high school student, what should I learn to prepare for the age of AI?"
        },
        {
            "code": "EN2349B",
            "question": "What is the difference between AI and Machine Learning?"
        },
        {
            "code": "EN2349C",
            "question": "What is the most important thing in friendship?",
        },
        {
            "code": "EN2349D",
            "question": "How to have a good friend?",
        },
        {
            "code": "EN2349E",
            "question": "How to study and work well?",
        },
        {
            "code": "EN2349F",
            "question": "As a student, Which subjects are neccessary to do well in the age of AI?",
        },
        {
            "code": "EN2349G",
            "question": "How to get a good job right after graduation in the age of AI?",
        },


        {
            "code": "VN2349A",
            "question": "Là một học sinh cấp 3, tôi nên học gì để chuẩn bị cho thời đại AI?"
        },
        {
            "code": "VN2349B",
            "question": "Sự khác biệt giữa AI và Machine Learning là gì?"
        },
        {
            "code": "VN2349C",
            "question": "Điều gì là quan trọng nhất trong tình bạn?",
        },
        {
            "code": "VN2349D",
            "question": "Làm sao để có được một tình bạn tốt?",
        },
        {
            "code": "VN2349E",
            "question": "Làm sao để học và làm thật tốt?",
        },
        {
            "code": "VN2349F",
            "question": "Môn học nào thì phù hợp để làm tốt trong kỷ nguyên trí tuệ nhân tạo AI?",
        },
        {
            "code": "VN2349G",
            "question": "Làm sao để có việc tốt ngay sau khi tốt nghiệp?",
        },

    ],
    cachedTime: undefined
}